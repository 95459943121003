import Vue from 'vue'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'

const smbgListService = {
  getPatientSmbg({
    PatientNumber = null,
    Days = null,
    NoControlSolutions = false,
  } = {}) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .get('/web/patient/smbg', {
          params: {
            PatientNumber,
            Days,
            NoControlSolutions,
            currentClientTime,
          },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getPatientSmbg error', error, {
            PatientNumber,
            Days,
            currentClientTime,
          })
          reject(error)
        })
    })
  },

  getSmbgHistory(patientNumber, smbgId) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/smbg/history', {
          params: {
            patientNumber,
            smbgId,
          },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getSmbgHistory error', error, {
            patientNumber,
            smbgId,
          })
          reject(error)
        })
    })
  },
}

export default smbgListService
