<template>
  <v-dialog
    v-if="measurement.versionCount && measurement.versionCount > 0"
    v-model="showDialog"
    :max-width="800"
  >
    <template #activator="{ on }">
      <v-btn
        fab
        x-small
        :color="getAuditDialogBtnColor(measurement.versionCount)"
        class="lighten-5 elevation-1"
        v-on="on"
        data-testid="btn-open-audit-log"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </template>

    <DialogCard @closeDialog="showDialog = false">
      <template #title> Audit trail for BG value </template>
      <v-data-table
        :disable-sort="true"
        :fixed-header="true"
        :headers="getTableHeaders()"
        :items="tableData"
        item-key="rowVersionId"
        :loading="isLoading"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions,
        }"
        dense
      >
        <template #loading>
          <v-skeleton-loader
            v-for="i in skeletonCount"
            :key="`skeleton_${i}`"
            height="38"
            type="table-row"
          />
        </template>

        <template #no-data>
          <UnableToLoadData v-if="state === loadingState.LOAD_ERRORED" />
        </template>

        <template #item="{ item }">
          <tr>
            <td>
              <DateFormat :value="item.doneAt.time" data-testid="bgm-date" />
            </td>
            <td>
              <DateFormat
                :value="item.doneAt.time"
                :format="hourFormatString"
                data-testid="bgm-time"
              />
            </td>
            <td>
              <SmbgValue :measure="item" />
            </td>
            <td>
              <div v-if="item.isControl">
                <v-icon class="mr-2 pl-1">mdi-bookmark-check-outline</v-icon>
                <span data-testid="bgm-label">Control solution</span>
              </div>
              <SmbgTimePointLabel v-else :time-point="item.timepoint" />
            </td>
            <td>
              <small data-testid="item-modified-date-time">
                <DateFormat
                  :value="item.eventDoneAt.time"
                  format="dd-MMM-yyyy"
                  data-testid="item-modified-date"
                  class="d-block"
                />
                <DateFormat
                  :value="item.eventDoneAt.time"
                  format="HH:mm:ss"
                  data-testid="item-modified-time"
                />
              </small>
            </td>
            <td>
              <small>
                <span class="d-block">
                  <VersionBy :versionBy="item.versionBy"
                /></span>
                <span data-testid="item-version-reason">{{
                  item.versionReason
                }}</span>
              </small>
            </td>
          </tr>
        </template>
      </v-data-table>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import DateFormat from '@/components/DateFormat.vue'
import VersionBy from '@/components/VersionBy.vue'
import SmbgValue from '@/components/SmbgValue.vue'
import SmbgTimePointLabel from '../SmbgTimePointLabel.vue'
import DialogCard from '@/components/DialogCard.vue'

import loadingState from '@/constants/loadingState'
import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'
import service from '@/services/smbg-list-service'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import hourFormatter from '@/utils/date/hourFormatter'
import auditDialogMixin from '@/components/mixins/auditDialogMixin'

export default Vue.extend({
  name: 'SmbgHistoryDialog',
  components: {
    DialogCard,
    SmbgValue,
    SmbgTimePointLabel,
    DateFormat,
    VersionBy,
    UnableToLoadData: () => import('@/components/UnableToLoadData.vue'),
  },
  props: {
    measurement: { type: Object, required: true },
    patientNo: { type: String, required: true },
  },
  mixins: [auditDialogMixin],
  data() {
    return {
      state: loadingState.INITIAL,
      loadingState,
      data: null,
      showDialog: false,
      itemsPerPageOptions,
    }
  },
  watch: {
    showDialog(newShowDialog) {
      if (newShowDialog) {
        this.loadData()
      }
    },
  },
  computed: {
    ...selectedPatientMapGetters(),
    tableData() {
      return this.data?.items || []
    },
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    clockNotationDisplay() {
      return `(${this.clockNotation})`
    },
    hourFormatString() {
      return hourFormatter(this.clockNotation)
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    skeletonCount() {
      return this.measurement.versionCount > 10
        ? 10
        : this.measurement.versionCount
    },
  },
  methods: {
    getTableHeaders() {
      return [
        { text: 'Date', value: 'date', sortable: false },
        {
          text: `Time ${this.clockNotationDisplay}`,
          value: 'time',
          sortable: false,
        },
        { text: 'Value/Unit', value: 'dose', sortable: false },
        { text: 'Label', value: 'timepoint', sortable: false },
        { text: 'Modified', value: 'versionAt' },
        { text: 'By / reason', value: 'versionBy' },
      ]
    },
    loadData() {
      this.state = loadingState.LOADING
      service
        .getSmbgHistory(this.patientNo, this.measurement.id)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.data = response
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
})
</script>
