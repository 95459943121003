<template>
  <v-data-table
    v-if="items"
    data-testid="bgm-data-table"
    :headers="getTableHeaders()"
    :items="filteredItems"
    :page.sync="page"
    :hide-default-footer="!hasPagination"
    :footer-props="
      hasPagination
        ? {
            'items-per-page-options': itemsPerPageOptions,
          }
        : { 'items-per-page-options': [11000] }
    "
  >
    <template #top="{ pagination, options, updateOptions }">
      <v-row v-if="items.length !== 0" no-gutters>
        <v-col
          :class="[
            {
              'col-sm-12 col-lg-12 order-lg-last pr-6': hasMultipleTimepoints,
            },
            { 'col-sm-3': !hasMultipleTimepoints },
          ]"
          class="pl-2 py-2 col-md-6 col-lg-6 order-md-first align-center"
        >
          <div class="d-flex justify-space-between pb-3">
            <v-select
              v-if="hasMultipleTimepoints"
              v-model="filter"
              :items="doseTimepoints"
              item-value="value"
              placeholder="Filter by label"
              dense
              outlined
              multiple
              clearable
              hide-details
              class="ml-2 mt-0 timepoint-filter"
            >
              <template #selection="{ item }">
                <v-chip label small class="narrow">
                  <span class="hidden-sm-and-down">{{ item.textShort }}</span>
                  <span class="hidden-md-and-up">{{ item.text }}</span>
                </v-chip>
              </template>
            </v-select>
            <v-select
              v-if="!maxDays"
              v-model="daysFilter"
              :items="daysFilterOptions"
              outlined
              dense
              hide-details
              class="ml-2 days-filter shrink"
            >
              <template #selection="{ item }">
                <span class="font-x-small">
                  {{ item.textShort }}
                </span>
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col
          v-if="hasPagination"
          :class="[
            {
              'col-sm-12 order-sm-first col-lg-12 order-lg-first':
                hasMultipleTimepoints,
            },
            { 'col-sm-9': !hasMultipleTimepoints },
          ]"
          class="col-md-6"
        >
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            :items-per-page-options="itemsPerPageOptions"
            class="top-footer-item"
        /></v-col>
      </v-row>
    </template>

    <template #item="{ item }">
      <SmbgTableNoDataRow
        v-if="item.measurementState === 'no_data'"
        :item="item"
        :hideAudit="hideAudit"
      />
      <SmbgTableMissingRow
        v-else-if="item.measurementState === 'pending'"
        :item="item"
        :hideAudit="hideAudit"
      />
      <SmbgTableMeasurementRow
        v-else-if="item.measurementState === 'specified'"
        :item="item"
        :patient-no="patientNo"
        :hideAudit="hideAudit"
      />
    </template>
    <template #no-data>
      <template v-if="isLoading">Loading... Please wait </template>
      <template v-else>
        <UnableToLoadData v-if="loadingState === loadingState.LOAD_ERRORED" />
        <div v-else-if="hasActiveFilter" class="py-16 mb-13">
          <div class="d-flex align-center justify-center mx-auto my-4">
            <ImgNoSmbg />
          </div>

          <strong class="no-data-text d-block mb-4"
            >There are no blood glucose values<br />to display for the time
            period selected.</strong
          >

          <v-btn
            v-if="!maxDays"
            @click="clearFilters"
            rounded
            outlined
            class="clear-link"
            >Show all dates</v-btn
          >
        </div>
        <div v-else class="py-16 my-16">
          <div class="d-flex align-center justify-center mx-auto my-4">
            <ImgNoSmbg />
          </div>

          <strong class="no-data-text d-block mb-4"
            >There are no blood glucose values<br />to display for this
            patient.</strong
          >
        </div>
      </template>
    </template>
  </v-data-table>
</template>

<script>
import Vue from 'vue'

import SmbgTableMeasurementRow from './_SmbgTableMeasurementRow.vue'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'

import loadingState from '@/constants/loadingState'
import service from '@/services/smbg-list-service'
import period from './period'

import globalProgressActionsMixins from '@/components/mixins/store/globalProgressActionsMixins'

const isInteger = number => number === parseInt(number, 10)

export default Vue.extend({
  name: 'SmbgList',
  mixins: [globalProgressActionsMixins],
  components: {
    ImgNoSmbg: () => import('@/components/images/ImgNoSmbg'),
    UnableToLoadData: () => import('@/components/UnableToLoadData'),
    SmbgTableNoDataRow: () => import('./_SmbgTableNoDataRow.vue'),
    SmbgTableMissingRow: () => import('./_SmbgTableMissingRow.vue'),
    SmbgTableMeasurementRow,
  },
  props: {
    patientNo: { type: String, required: true },
    maxDays: { type: Number, required: false, default: null },
    noControlSolutions: { type: Boolean, required: false, default: false },
    hideAudit: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      page: 1,
      itemsPerPageOptions,
      loadingState: loadingState.INITIAL,
      metadata: { timePoints: [] },
      daysFilter: period.FOURTEEN_DAYS,
      daysFilterOptions: [
        { text: 'Last 7 days', textShort: '7 days', value: period.WEEK },
        {
          text: 'Last 14 days',
          textShort: '14 days',
          value: period.FOURTEEN_DAYS,
        },
        { text: 'All data', textShort: 'All data', value: period.ALL_DATA },
      ],
      items: [],
      filter: [],
      doseTimepoints: [],
    }
  },
  computed: {
    ...selectedPatientMapGetters(),
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    clockNotationDisplay() {
      return `(${this.clockNotation})`
    },
    hasPagination() {
      return this.daysFilter === period.ALL_DATA
    },
    isLoading() {
      return this.loadingState === loadingState.LOADING
    },
    isLoadSucceeded() {
      return this.loadingState === loadingState.LOAD_SUCCEEDED
    },
    isLoadErrored() {
      return this.loadingState === loadingState.LOAD_ERRORED
    },
    hasActiveFilter() {
      return this.items.length !== this.filteredItems.length
    },
    hasMultipleTimepoints() {
      return this.metadata?.timePoints?.length > 1
    },
    filteredItems() {
      if (this.items.length === 0) return []
      let result = this.items.filter(x => x.daysOld <= this.daysFilter)

      if (this.filter.length > 0) {
        result = result.filter(
          x =>
            this.filter.includes(x.measurement?.timepoint) ||
            x.measurementState !== 'specified'
        )

        if (result.every(x => x.measurementState !== 'specified')) {
          return []
        }
      }

      return result
    },
  },
  methods: {
    getDoseTimepoints() {
      if (this.hasMultipleTimepoints) {
        return this.metadata.timePoints.map(timepoint => {
          return {
            text: this.$t(`bg-time-point.${timepoint}`),
            textShort: this.$t(`bg-time-point-short.${timepoint}`),
            value: timepoint,
          }
        })
      }
      return []
    },
    refreshData() {
      this.fetchSmbgList()
    },
    clearFilters() {
      if (this.filter.length !== 0) {
        this.daysFilter = period.ALL_DATA
      }
    },
    getTableHeaders() {
      const list = [
        { text: 'Date', value: 'date', sortable: false },
        {
          text: `Time ${this.clockNotationDisplay}`,
          value: 'time',
          sortable: false,
        },
        {
          text: 'BG value',
          value: 'dose',
          sortable: false,
          class: 'padding-left',
        },
        { text: 'Label', value: 'label', sortable: false },
        { text: '', value: 'audit', sortable: false },
      ]

      return this.hideAudit ? list.filter(item => item.value !== 'audit') : list
    },
    fetchSmbgList() {
      this.items = []
      this.globalStartLoadingRequested()
      this.loadingState = loadingState.LOADING

      service
        .getPatientSmbg({
          PatientNumber: this.patientNo,
          Days: this.maxDays,
          NoControlSolutions: this.noControlSolutions,
        })
        .then(response => {
          let items = []

          if (response.items?.length) {
            items = response.items.map(item => {
              let displayValue

              if (item.measurement?.isHigh) displayValue = 'HI'
              else if (item.measurement?.isLow) displayValue = 'LO'
              else displayValue = item.measurement?.value

              if (
                item.measurement?.minimumDecimalsDisplay &&
                isInteger(displayValue)
              ) {
                displayValue = displayValue.toFixed(
                  item.measurement?.minimumDecimalsDisplay
                )
              }

              return {
                displayUnit: item.measurement?.unit,
                displayValue,
                doneAt: item.measurement?.doneAt?.time || item.date,
                isHypoglycemia: item.measurement?.isHypoglycemia,
                isHigh: item.measurement?.isHigh,
                isLow: item.measurement?.isLow,
                timepoint: item.measurement?.timepoint,
                hypoId: item.measurement?.hypo?.hypoId,
                hypoNumber: item.measurement?.hypo?.hypoNumber,
                isMissingForDate: !item.measurement,
                measurementState: item.measurementState,
                measurement: item.measurement,
                date: item.date,
                daysOld: item.daysOld,
              }
            })
          }

          this.items = items
          this.metadata = response.metadata
          this.doseTimepoints = this.getDoseTimepoints()
          this.loadingState = loadingState.LOAD_SUCCEEDED
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.globalStopLoadingRequested()
        })
    },
  },
  created() {
    this.fetchSmbgList()
  },
  watch: {
    daysFilter() {
      this.page = 1
    },
    filter() {
      this.page = 1
    },
  },
})
</script>

<style lang="scss" scoped>
.timepoint-filter {
  width: 250px;

  .narrow {
    padding: 0 7px;
  }
}
.days-filter {
  width: 110px;
}
.font-x-small {
  font-size: 12px;
}
.v-data-table {
  .top-footer-item {
    border-top: none;
  }
  ::v-deep thead th {
    background-color: $nn-TB_T98;
    letter-spacing: -0.02em;
    color: $nn-D_T20 !important;
    height: 37px !important;
    border-bottom: none !important;
    > span {
      display: inline-flex;
      height: 100%;
      align-items: center;
    }
  }
}

.clear-link {
  color: $nn-sea-blue;
}
</style>
