<template>
  <tr>
    <td>
      <DateFormat :value="item.date" data-testid="bgm-date" />
    </td>
    <td>
      <DateFormat
        :value="item.measurement.doneAt.time"
        :format="hourFormatString"
        data-testid="bgm-time"
      />
    </td>
    <td>
      <HypoFormDialog
        v-if="item.measurement.hypo"
        :patient-no="patientNo"
        :hypo-id="item.measurement.hypo.hypoId"
      >
        <span class="px-0 link-span" data-testid="bgm-hypo-cta">
          <SmbgValue :measure="item.measurement">
            <v-icon small class="ml-1 is-hypo">mdi-open-in-new</v-icon>
          </SmbgValue>
        </span>
      </HypoFormDialog>

      <SmbgValue v-else :measure="item.measurement" />
    </td>
    <td>
      <div v-if="item.measurement.isControl">
        <v-icon class="mr-2 pl-1">mdi-bookmark-check-outline</v-icon>
        <span data-testid="bgm-label">Control solution</span>
      </div>
      <SmbgTimePointLabel v-else :time-point="item.measurement.timepoint" />
    </td>
    <td v-if="!hideAudit">
      <SmbgHistoryDialog
        :patient-no="patientNo"
        :measurement="item.measurement"
      >
      </SmbgHistoryDialog>
    </td>
  </tr>
</template>

<script>
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import SmbgHistoryDialog from './_SmbgHistoryDialog'
import SmbgValue from '@/components/SmbgValue'
import SmbgTimePointLabel from '../SmbgTimePointLabel'
import DateFormat from '@/components/DateFormat'
import hourFormatter from '@/utils/date/hourFormatter'

export default {
  name: 'SmbgMeasurementRow',
  components: {
    SmbgHistoryDialog,
    SmbgValue,
    SmbgTimePointLabel,
    DateFormat,
    HypoFormDialog: () => import('@/components/hypo-form/HypoFormDialog.vue'),
  },
  props: {
    item: { type: Object, required: true },
    patientNo: { type: String, required: true },
    hideAudit: { type: Boolean, required: false, default: false },
  },
  computed: {
    ...selectedPatientMapGetters(),
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    clockNotationDisplay() {
      return `(${this.clockNotation})`
    },
    hourFormatString() {
      return hourFormatter(this.clockNotation)
    },
  },
}
</script>

<style scoped>
.link-span {
  cursor: pointer;
  display: inline-block;
}
</style>
